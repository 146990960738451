import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreateResourceDto, UnLikeResourceDto, Resource } from './like.model';

@Injectable({
  providedIn: 'root',
})
export class LikesService {
  private baseUrl = 'https://api.raqn.io/user-preferences/v1';

  constructor(private http: HttpClient) {}

  getResourcesWithLikes(uri?: string): Observable<Resource[]> {
    let params = new HttpParams();
    if (uri) {
      params = params.set('uri', uri);
    }

    return this.http.get<Resource[]>(`${this.baseUrl}/resources/likes`, {
      params,
    });
  }

  likeResource(
    likeResourceDto: CreateResourceDto,
  ): Observable<{ message: string }> {
    return this.http.post<{ message: string }>(
      `${this.baseUrl}/resources/like`,
      likeResourceDto,
    );
  }

  unlikeResource(unlikeResourceDto: UnLikeResourceDto): Observable<void> {
    const params = new HttpParams({ fromObject: { ...unlikeResourceDto } });

    return this.http.delete<void>(`${this.baseUrl}/resources/like`, {
      params,
    });
  }

  getUserLikes(uri?: string): Observable<Resource[]> {
    let params = new HttpParams();
    if (uri) {
      params = params.set('uri', uri);
    }

    return this.http.get<any>(`${this.baseUrl}/users/likes`, {
      params,
    });
  }
}
